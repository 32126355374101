<template>
  <el-card class="!border-none mb10 status-tag" v-show="sysTitle" shadow="never">
    <div style="display: flex">
      <el-alert
          type="warning"
          :title="sysTitle"
          :closable="false"
          show-icon
          style="flex-direction: row"
      />
      <a href="/#/setUp/systemSetup"
         style="width:60px;height:40px;line-height: 40px;margin-left: 5px;color:rgb(59 82 224)">{{ $t('更新') }}</a>
    </div>
  </el-card>
  <div>
    <el-card shadow="hover">
      <h3 class="title">{{ $t('电话会议') }}</h3>
      <div class="system-user-search">
        <div style="padding-bottom:10px;">
          <div>
            <el-button size="small" type="primary" @click="onOpenAddMenu">{{ $t('添加') }}</el-button>
          </div>
        </div>
        <div>
          <el-input size="small" v-model="input" :placeholder="$t('会议号或名称')" prefix-icon="el-icon-search"
                    style="max-width: 180px;margin-right:3px;" @keyup.enter="handleSearch"></el-input>
          <el-button size="small" type="primary" @click="handleSearch">{{ $t('查询') }}</el-button>
        </div>
      </div>
      <el-table :data="tableData" stripe
                style="width: 100%;margin-top:15px;" class="table" id="out-table" @sort-change="changeTableSort">
        <el-table-column prop="name" :label="$t('名称')" show-overflow-tooltip></el-table-column>
        <el-table-column prop="number" :label="$t('号码')" show-overflow-tooltip
                         sortable="custom"></el-table-column>
        <el-table-column prop="type" :label="$t('类型')" show-overflow-tooltip>
          <template #default="scope">
            <el-tag size="medium" v-if="scope.row.type == 0">{{ $t('开放型') }}</el-tag>
            <el-tag size="medium" v-if="scope.row.type == 1">{{ $t('密码型') }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="max_members" :label="$t('容量')" show-overflow-tooltip></el-table-column>
        <el-table-column prop="create_time" :label="$t('创建时间')" show-overflow-tooltip
                         sortable="custom" width="180"></el-table-column>
        <el-table-column prop="path" :label="$t('操作')" width="100" fixed="right">
          <template #default="scope">
            <el-button size="mini" type="text" @click="onOpenEditMenu(scope.row)">{{ $t('编辑') }}</el-button>
            <el-button size="mini" type="text" @click="onRowDel(scope.row)"
                       class="u-delete" style="margin-left: 0px !important;">{{ $t('删除') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleSizeChange"
                     @current-change="onHandleCurrentChange" class="mt15" :pager-count="5" :page-count="pageCount"
                     :current-page="pageNum" background :page-size="pageSize"
                     layout="total, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
      <el-pagination v-else @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                     :page-size="pageSize" small layout=" prev, pager, next" :page-count="pageCount"
                     :current-page="pageNum"
                     :total="total" class="mt15"></el-pagination>
    </el-card>
    <AddMenu ref="addMenuRef" @print="print"/>
    <EditMenu ref="editMenuRef" @print="print"/>
  </div>
</template>
<script>
import {
  ref,
  reactive,
  toRefs,
  onMounted
} from 'vue';
import {useI18n} from "vue-i18n";
import AddMenu from './Conconference/addMenu';
import EditMenu from './Conconference/editMenu';
import {
  ElMessageBox,
  ElNotification
} from 'element-plus';
import {
  getConference,
  deleteConference,
} from '@/http/setUp.api.js';

export default {
  name: 'grouping',
  components: {
    AddMenu,
    EditMenu
  },
  setup() {
    const {t}       = useI18n()
    const state     = reactive({
      sysTitle: '',
      input: '',
      tableData: [],
      pageSize: 5,//每页数据条数
      pageNum: 1,//页码
      total: 0,//总数据条数
      pageCount: 0,//总页数
      sortBy: '',
      sortType: '',
      clientWidth: '',
    });
    const blackList = (state) => {
      let params = {
        page: state.pageNum,
        keyword: state.input,
        sort_type: state.sortType,
        sort_by: state.sortBy,
      }
      getConference(params).then(res => {
        if (res.code == 200) {
          state.tableData = res.data.list;
          state.pageCount = res.data.pages;
          state.pageSize  = res.data.length;
          state.total     = res.data.rows;
          state.sysTitle  = res.tag;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      });
    }
    const print     = () => {
      blackList(state);
    }
    onMounted(() => {
      state.clientWidth = window.innerWidth;
      blackList(state);
    })
    const addMenuRef            = ref();
    // 打开新增菜单弹窗
    const onOpenAddMenu         = () => {
      addMenuRef.value.openDialog();
    };
    const editMenuRef           = ref();
    // 打开编辑菜单弹窗
    const onOpenEditMenu        = (row) => {
      editMenuRef.value.openDialog(row);
    };
    // 当前行删除
    const onRowDel              = (row) => {
      ElMessageBox.confirm(t("你确定要删除该会议吗"), t("提示"), {
        confirmButtonText: t("确定"),
        cancelButtonText: t("取消"),
        type: "warning",
        callback: (action) => {
          if (action === "confirm") {
            let params = {
              id: row.id
            }
            deleteConference(params).then(res => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("删除成功"),
                  type: "success",
                  duration: 2000,
                });
                blackList(state)
              } else {
                ElNotification({
                  title: t('失败'),
                  message: res.msg,
                  type: 'error',
                  duration: 2000,
                });
              }
            })
          }
        }
      })
    };
    const changeTableSort       = (column) => {
      state.sortBy   = column.prop;
      state.sortType = column.order;
      blackList(state)
    }
    // 分页改变
    const onHandleSizeChange    = (val) => {
      state.pageSize = val;
      blackList(state)
    };
    // 分页改变
    const onHandleCurrentChange = (val) => {
      state.pageNum = val;
      blackList(state)
    };

    const handleSearch = () => {
      blackList(state);
    }
    return {
      addMenuRef,
      onOpenAddMenu,
      ...toRefs(state),
      onRowDel,
      changeTableSort,
      onHandleSizeChange,
      onHandleCurrentChange,
      editMenuRef,
      onOpenEditMenu,
      print,
      handleSearch
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  :deep(.hidden-columns) {
    visibility: visible !important;
    position: relative !important;
    z-index: 1 !important;
  }
}

.system-user-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mt15 {
  text-align: center;
  margin-top: 15px;
}

.title {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 15px;
}
</style>
