<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('会议编辑')" v-model="isShowDialog" width="60%">
      <el-divider></el-divider>
      <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="90px" label-position="top"
               ref="validateFrom" :rules="rules">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('名称')" prop="name">
              <el-input v-model="ruleForm.name" :placeholder="$t('名称')" maxlength="16" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('号码')" prop="number">
              <el-input v-model="ruleForm.number" :placeholder="$t('号码')" maxlength="8"
                        onkeyup="value=value.replace(/[^\d]/g,'')" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('容量')" prop="max_members">
              <el-input-number v-model="ruleForm.max_members" placeholder="" maxlength="3" :min="3" :max="999"
                               clearable></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('类型')">
              <el-radio-group v-model="ruleForm.type">
                <el-radio :label="0" :value="0">{{ $t('开放型') }}</el-radio>
                <el-radio :label="1" :value="1">{{ $t('密码型') }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-if="ruleForm.type == '1'">
            <el-form-item :label="$t('密码')">
              <el-input v-model="ruleForm.password" :placeholder="$t('长度三到六位的数字')" maxlength="6"
                        clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  ElNotification
} from 'element-plus';
import {
  findConference,
  updateConference
} from '@/http/setUp.api.js';

export default {
  name: 'groupingEditMenu',
  emits: ['print'],
  setup(props, context) {
    const {t}                = useI18n();
    const state              = reactive({
      isShowDialog: false,
      id: '',
      ruleForm: {
        name: '',
        number: '',
        lang: '',
        type: '',
        max_members: '',
        password: '',
      },
      activeName: 'first',
      tabPosition: 'left',
    });
    const conferenceEditData = (state) => {
      let params = {
        id: state.id
      }
      findConference(params).then(res => {
        if (res.code == 200) {
          state.ruleForm = res.data;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      }).catch(err => {
        console.log(err, 'err');
      })
    }
    const validateFrom       = ref()

    //校验规则
    const rules       = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入名称"),
          trigger: "blur"
        }],
        number: [{
          required: true,
          message: t("请输入号码"),
          trigger: "blur"
        },
          {
            pattern: /^\d*$/,
            message: t('会议号只允许添加数字'),
            trigger: 'blur',
          },],
        max_members: [{
          required: true,
          message: t("请输入最大成员数"),
          trigger: "blur"
        }],
        lang: [{
          required: true,
          message: t("请选择语言"),
          trigger: "change"
        }],
      };
      return rule;
    })
    // 打开弹窗
    const openDialog  = (row) => {
      state.id           = row.id;
      state.isShowDialog = true;
      conferenceEditData(state)
    };
    // 关闭弹窗
    const closeDialog = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel    = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit    = () => {
      validateFrom.value.validate(valid => {
        if (valid) {
          let params = {
            id: state.id,
            name: state.ruleForm.name,
            number: state.ruleForm.number,
            lang: state.ruleForm.lang,
            type: state.ruleForm.type,
            max_members: state.ruleForm.max_members,
            password: state.ruleForm.password,
          };
          updateConference(params).then(res => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("编辑成功"),
                type: 'success',
                duration: 2000,
              });
              state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      })
    };
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm    = () => {
      state.ruleForm.name   = '';
      state.ruleForm.phone  = '';
      state.ruleForm.record = '';
      state.ruleForm.from   = '';
    };
    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      ...toRefs(state),
      validateFrom,
      rules
    };
  },
  data() {
    const generateData = () => {
      const data = []
      for (let i = 1; i <= 15; i++) {
        data.push({
          value: i,
          desc: `Option ${i}`,
        })
      }
      return data
    }
    return {
      data: generateData(),
      value: [],
    }
  },
};
</script>
<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
}
</style>
